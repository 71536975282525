<template>
  <div class="Financial">
    <TheSubHeader
      ref="theSubHeader"
      icon="money"
      title="Financeiro"
      page-title-tag="Financial Consolidated Operations"
      type="routerTabs"
      organizer-gap="1rem"
    > 
      <template #afterButtons>
        <BaseButton
          v-if="permissions && permissions.editVehicles && permissions.editVehicles.isVisible"
          icon="truck-delivered"
          color="primary-base"
          label="Tipo de Veículo"
          class="car-type-button"
          :track="trackSubheaderButtonString('Financial Consolidated Operations', 'Financial Edit Vehicle')"
          @click="goToEditVehicle()"
        />
        <BaseButton
          icon="status"
          color="primary-base"
          label="Custo de operação"
          class="operation-button"
          :track="trackSubheaderButtonString('Financial Consolidated Operations', 'Operation Cost')"
          @click="openModalOperation()"
        />
      </template>
      <template #filter>
        <section class="Financial__subheaderTab Financial__subheaderTab--consolidate-filters">
          <!-- date filter -->
          <BaseDatePicker
            v-model="formattedDateRange"
            label="Intervalo entre datas (max. 15 dias)"
            :max-range="15"
            @input="handlerOperationConsolidateRequest"
          />

          <!-- users filter -->
          <BaseTreeSelect
            v-model="filters.users"
            name="Nome do Usuário"
            label="Nome do Usuário"
            placeholder="Selecione"
            suggestions="searchUsers"
            multiple
            @input="updateTags(), handlerOperationConsolidateRequest()"
          />
        </section>  
      </template>

      <!-- filters tag -->
      <template v-if="allTags.length > 0" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>


    <div class="Financial__content">
      <DataTable
        :header="operationConsolidate.data.headers"
        :table="operationConsolidate.data.content"
        @download="download"
        @remove="openDeleteModal"
      />

      <Pagination @page="fetchConsolidatePayment" @paginate="fetchConsolidatePayment" />
  
      <Dialog
        v-if="showConfirmDialog"
        warning="Deseja realmente excluir o arquivo?"
        label-cancel="Não"
        label-confirm="Sim"
        @confirm="confirmDeleteFile"
        @cancel="showConfirmDialog = false"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
//Components
import { TheSubHeader } from '@/components/organisms'
import { BaseDatePicker, BaseTreeSelect } from '@/components/atoms'
import { DataTable, Pagination, FiltersTag } from '@/components/molecules'
import { BaseButton } from '@/components/atoms'
import { financial } from '@/api'
//Mixins
import FinancialMixin from '@/mixins/financial'

export default {
  name: 'FinancialConsolidatedOperations',
  components: {
    TheSubHeader,
    BaseDatePicker,
    BaseTreeSelect,
    FiltersTag,
    BaseButton,
    DataTable,
    Pagination
  },
  mixins:[FinancialMixin],
  data() {
    return {
      //Filters
      filters: {
        dateRange: {
          init: null,
          end: null,
        },
        users: []
      },

      //Tags
      allTags: [],

      showModal: false,
      fileId: null,
      showConfirmDialog: false
    }
  },

  computed: {
    ...mapState({
      permissions: state => state.auth.permissions,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      operationConsolidate: state => state.financial.operationConsolidate
    }),
  },

  mounted() {
    this.clearPagination(20)
    this.fetchConsolidatePayment()
  },
  
  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
      setPagination: 'pagination/setPagination',
      clearPagination: 'pagination/clearPagination',
      fetchOperationConsolidate: 'financial/fetchOperationConsolidate'
    }),

    updateTags(){
      this.allTags = [...this.filters.users]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.users = this.filters.users.filter((tag) => tag.name !== name)
      this.handlerOperationConsolidateRequest()
    },

    clearAllTags(){
      this.allTags = []
      this.filters.users = []
      this.filters.dateRange.init = null
      this.filters.dateRange.end = null
      this.handlerOperationConsolidateRequest()
    },

    handlerOperationConsolidateRequest() {
      this.clearPagination(20).then(() => this.fetchConsolidatePayment())
    },

    async fetchConsolidatePayment() {
      const params = {
        page: this.page,
        paginate: this.paginate,
        obj: {
          date_start: this.filters?.dateRange?.init,
          date_end: this.filters?.dateRange?.end,
          user_id: this.filters?.users
        }
      }
      await this.fetchOperationConsolidate(params)
      this.setPagination(this.operationConsolidate)
    },

    async confirmDeleteFile() {
      this.setLoading(true)
      await financial.deleteUploadedFile(this.fileId, () => {
        this.showConfirmDialog = false
        this.$toast.success('Arquivo excluído', { position: 'bottom-center' })
      }, (e) => e)
      this.setLoading(false)
    },

    async download(file) {
      this.setLoading(true)
      await financial.downloadFinancialConsolidation(file.id, (r) => r, (e) => e)
      this.setLoading(false)
    },

    openDeleteModal(file) {
      this.showConfirmDialog = true
      this.fileId = file.id
    },
  },
}
</script>

<style lang="scss" scoped>
.Financial {
  &__subheaderTab {
    display: grid;
    align-items: end;
    grid-gap: 1rem;
      @media #{$mobile-view} {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &--consolidate-filters {
      grid-template-columns: 250px 300px;
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
    max-width: 1180px;
    padding: 0 0 3rem 0;
    @media (max-width: $viewport-lg) {
      padding: 0 1rem 3rem 1rem;
    }
  }
}
</style>